export const jogStores = [
    { store: 101, name: "MEPPEL" },
    { store: 102, name: "OSS" },
    { store: 103, name: "EINDHOVEN (D)" },
    { store: 104, name: "ARNHEM" },
    { store: 105, name: "DEN BOSCH" },
    { store: 106, name: "OOSTERHOUT" },
    { store: 107, name: "TILBURG" },
    { store: 108, name: "WAALWIJK" },
    { store: 110, name: "ELBURG" },
    { store: 111, name: "WINSCHOTEN" },
    { store: 112, name: "TIEL" },
    { store: 113, name: "BERGEN OP ZOOM" },
    { store: 116, name: "ROERMOND" },
    { store: 118, name: "VELDHOVEN" },
    { store: 120, name: "PUTTEN" },
    { store: 121, name: "UDEN" },
    { store: 122, name: "HEERLEN" },
    { store: 123, name: "SITTARD" },
    { store: 124, name: "MAASTRICHT" },
    { store: 125, name: "BREDA" },
    { store: 127, name: "ETTEN-LEUR" },
    { store: 128, name: "ROOSENDAAL" },
    { store: 129, name: "VENRAY" },
    { store: 130, name: "LEIDEN" },
    { store: 131, name: "BRUNSSUM" },
    { store: 134, name: "DELFT" },
    { store: 135, name: "LEIDSCHENDAM" },
    { store: 137, name: "MIDDELBURG" },
    { store: 140, name: "LEEUWARDEN" },
    { store: 141, name: "HEERENVEEN" },
    { store: 142, name: "SNEEK" },
    { store: 143, name: "DRACHTEN" },
    { store: 144, name: "GRONINGEN" },
    { store: 145, name: "FRANEKER" },
    { store: 146, name: "KAMPEN" },
    { store: 147, name: "EMMELOORD" },
    { store: 149, name: "GRONINGEN" },
    { store: 151, name: "ZWOLLE" },
    { store: 153, name: "WAGENINGEN" },
    { store: 154, name: "DOETINCHEM" },
    { store: 157, name: "ZEVENAAR" },
    { store: 159, name: "HENGELO" },
    { store: 160, name: "HOOFDDORP" },
    { store: 162, name: "HARDERWIJK" },
    { store: 163, name: "ENSCHEDE" },
    { store: 167, name: "EDE" },
    { store: 169, name: "ALMELO" },
    { store: 174, name: "NIEUWEGEIN" },
    { store: 175, name: "NIJMEGEN" },
    { store: 176, name: "NIJMEGEN" },
    { store: 177, name: "ARNHEM" },
    { store: 179, name: "AMERSFOORT" },
    { store: 180, name: "HARDENBERG" },
    { store: 181, name: "GROOTEBROEK" },
    { store: 184, name: "ROTTERDAM" },
    { store: 185, name: "DEN BOSCH" },
    { store: 186, name: "ZAANDAM" },
    { store: 187, name: "ZOETERMEER" },
    { store: 188, name: "ALKMAAR" },
    { store: 193, name: "GOIRLE" },
    { store: 197, name: "NOORDWIJK" },
    { store: 199, name: "EINDHOVEN ( W)" },
    { store: 201, name: "SCHIJNDEL" },
    { store: 203, name: "WEERT" },
    { store: 204, name: "WINTERSWIJK" },
    { store: 207, name: "VENLO" },
    { store: 208, name: "EMMEN" },
    { store: 209, name: "ALMERE" },
    { store: 210, name: "HELLEVOETSLUIS" },
    { store: 212, name: "VEENENDAAL" },
    { store: 213, name: "UTRECHT" },
    { store: 215, name: "DEVENTER" },
    { store: 216, name: "NAALDWIJK" },
    { store: 217, name: "HELMOND" },
    { store: 218, name: "ZUTPHEN" },
    { store: 219, name: "HEERHUGOWAARD" },
    { store: 220, name: "CAPELLE" },
    { store: 221, name: "APELDOORN" },
    { store: 222, name: "ASSEN" },
    { store: 223, name: "BEEK" },
    { store: 224, name: "SCHAGEN" },
    { store: 225, name: "HOORN" },
    { store: 226, name: "GOES" },
    { store: 227, name: "Alphen ad Rijn" },
    { store: 700, name: "JeansCentre.nl" },
];
